import Head from 'components/head'
import HomePage from 'components/pages/homePage'
import Layout from 'layouts'
import React from 'react'
//import { helloWorld } from '@rtorcato/utils'
import { metaTagData } from 'config'

const IndexPage = (): React.ReactElement => {
  //console.log(helloWorld())

  return (
    <>
      <Head metaTagData={metaTagData} />
      <Layout>
        <HomePage />
      </Layout>
    </>
  )
}

export default IndexPage

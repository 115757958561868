import HaveProject from 'components/haveProject'
import MastHead from 'components/mastHead'
// import Masthead from 'components/mastHead'
// import Parallax from 'components/Parallax'
import React from 'react'
import SectionBeautifulApps from './SectionBeautifulApps'
/* import SectionCompanies from './sectionCompanies'
import SectionComponents from './sectionComponents'
import SectionCustomApps from './sectionCustomApps' */
import SectionFeatured from './sectionFeatured'
import SectionShape from 'components/sectionShape'
import SectionTechnology from './sectionTechnology'
// import ScrollAnimation from 'react-animate-on-scroll'
import mastheadImage from './img/masthead.jpg'
import { motion } from 'framer-motion'
import styleConfig from 'config/styleConfig'

/*
const ParallaxTextStyle = css`
  font-size: 59px;
  color: white;
  font-weight: 300;
`
const parallaxContentStyle = css`
  z-index: 1;
  width: 100%;
  height: 100%;
  align-items: center;
  display: flex;
`
*/

const HomePage = (): React.ReactElement => {
  //background-image: url('/img/home-page/masthead.jpg');
  return (
    <>
      <MastHead backgroundImage={mastheadImage} largeMode>
        <div data-aos="fade-up" className="font-light">
          <h1 className="lg:text-6xl text-4xl">Simple Design, Advanced&nbsp;Technology</h1>
          <h2 className="text-xl lg:text-2xl">We Are Thinkers, Innovators, Builders</h2>
        </div>
      </MastHead>
      <SectionBeautifulApps />
      {/*
      <SectionShape shape={SectionShape.shapes.curveUpRight} color={styleConfig.colors.gray[200]} />*/}
      <div className="bg-gray-200">
        <SectionTechnology className="mt-10 mb-24" />
        <SectionShape className="-mt-40" shape={SectionShape.shapes.curveUpLeft} color={'white'} />
      </div>

      {/*<SectionCompanies className="mt-10 mb-24" />
      <SectionCustomApps className="mt-10 mb-24" />
      <SectionComponents className="mt-10 mb-24" />
      <SectionShape shape={SectionShape.shapes.middleCurveUp} color={styleConfig.colors.gray[200]} />*/}
      <SectionFeatured className="bg-white" />
      <HaveProject />
    </>
  )
}

export default HomePage

import FeatureGrid from 'components/featureGrid'
import { Link } from 'gatsby'
import React from 'react'
import featuredProjectData from 'models/featuredProjectData'
import { sitePaths } from 'config/paths'
import styled from '@emotion/styled'

const Section = styled.section``
interface IProps {
  className?: string
}
/*
function byDate(a: { date: string }, b: { date: string }) {
  //chronologically by year, month, then day
  return new Date(a.date).valueOf() - new Date(b.date).valueOf() //timestamps
}
function byId(a: IFeaturedProject, b: IFeaturedProject) {
  return parseInt(a.id + '') - parseInt(b.id + '')
}

const authorImage = {
  backgroundImage: `url(https://images.unsplash.com/photo-1500522144261-ea64433bbe27?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=751&q=80)`,
}
*/

const SectionFeatured = ({ className }: IProps): React.ReactElement => {
  return (
    <Section className={`${className} relative`}>
      <div className={`container mx-auto mb-10`}>
        <div className="text-center my-10 font-thin">
          <h3 className="text-4xl" data-aos="fade-down">
            Recent Projects
          </h3>
          <h4 className="text-xl" data-aos="fade-down">
            Here’s what we&apos;ve been up to recently
          </h4>
        </div>
        <FeatureGrid data={featuredProjectData.filter((_, idx) => idx < 3)} />
        <div className="flex justify-center lg:pb-10 px-4">
          <Link data-aos="fade-down" to={sitePaths.appLinks.portfolio} className="text-base btn lg:w-2/3 lift">
            View all Featured Projects
          </Link>
        </div>
      </div>
    </Section>
  )
}

export default SectionFeatured

import React from 'react'
import styled from '@emotion/styled'

interface ISectionShape {
  viewBox: string
  path: string
}
interface ISectionShapes {
  [name: string]: ISectionShape
}
interface IProps {
  className?: string
  shape: ISectionShape
  color: string
}

const Div = styled.div`
  position: relative !important;
  width: 100%;
  height: auto;
  position: absolute;
  pointer-events: none;
  transform-origin: top center;
  transform: scale(2);
  overflow: hidden;
  background: transparent;
  svg {
    width: 100%;
    overflow: hidden;
    height: auto;
    overflow: hidden;
  }
`

const shapes = {
  middleCurveUp: {
    // middle curve up
    viewBox: '0 0 2880 80',
    path: 'M0 48h2880V0h-720C1442.5 52 720 0 720 0H0v48z',
  },
  2: {
    viewBox: '0 0 2880 48',
    path:
      'M703.969 241.602l-.006-.003C716.081 262.97 723 287.677 723 314c0 68.917-47.425 126.757-111.42 142.665L246.7 556.937C226.465 564.729 204.481 569 181.5 569 81.26 569 0 487.74 0 387.5c0-34.256 9.49-66.296 25.985-93.633l-.016.008L141.512 77.548C162.753 33.305 207.123 2.273 258.951.12l.008-.12h251.04l.003.01c41.848.557 78.081 24.378 96.356 59.12l.001-.005 97.61 182.477z',
  },
  curveUpRight: {
    //card curve up right
    viewBox: '0 0 2880 480',
    path: 'M2160 0C1440 240 720 240 720 240H0v240h2880V0h-720z',
  },
  curveUpLeft: {
    viewBox: '0 0 2880 480',
    path: 'M720 0c720 240 1440 240 1440 240h720v240H0V0h720z',
  },
  5: {
    viewBox: '0 0 2880 72',
    path: 'M2880 0H0v54.112h720s289.42 40.248 705 0c416.5-37.733 735 0 735 0h720V0z',
  },
  6: {
    viewBox: '0 0 2880 56',
    path: 'M720 28H0v28h2880V28h-720S1874 0 1440 0 720 28 720 28z',
  },
  7: {
    //curve down to left
    viewBox: '0 0 112 690',
    path: 'M116 0H51v172C76 384 0 517 0 517v173h116V0z',
  },
}
// type ShapeNum = keyof typeof SectionShapes

const SectionShape = ({ className, shape, color }: IProps): React.ReactElement => {
  return (
    <Div className={`${className} ${color}`} style={{ color }}>
      <svg viewBox={shape.viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d={shape.path} fill="currentColor"></path>
      </svg>
    </Div>
  )
}

SectionShape.shapes = shapes

export default SectionShape

import { Link } from 'gatsby'
import React from 'react'
import desktopImg from './img/desktopImage.jpg'
import { motion } from 'framer-motion'
import { sitePaths } from 'config/paths'
import styleConfig from 'config/styleConfig'
import styled from '@emotion/styled'

const Container = styled.div`
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  overflow: hidden;
  ${styleConfig.breakPoints.lg} {
    overflow: initial;
    flex-direction: row;
  }
`

const TextContainer = styled.div`
  margin: 0;
  ${styleConfig.breakPoints.lg} {
    margin: 3rem 1rem 0;
    width: 50%;
  }
`
const ImageContainer = styled.div`
  margin: 0rem 3rem 0 2rem;
  padding: 0 0 10rem;
  text-align: center;
  img {
    max-width: 300px;
    ${styleConfig.breakPoints.lg} {
      max-width: 100%;
    }
  }
  ${styleConfig.breakPoints.lg} {
    margin: 0;
    width: 50%;
  }
  .img-skewed {
    margin: 0;
    padding-bottom: 0;
    perspective: 1500px;
    transform-style: preserve-3d;
  }
  [data-aos='img-skewed-item-left'] {
    opacity: 0;
  }
  [data-aos='img-skewed-item-left'],
  [data-aos='img-skewed-item-right'] {
    transition-property: opacity, transform, -webkit-transform;
    will-change: opacity, transform, -webkit-transform;
    backface-visibility: hidden;
  }
  [data-aos='img-skewed-item-left'].aos-animate,
  [data-aos='img-skewed-item-right'].aos-animate {
    opacity: 1;
    animation-duration: 500ms;
    transform: rotateY(-20deg) rotateX(5deg) translate3d(0, 100px, 0);
  }
`
const Section = styled.section`
  position: relative;
`
interface IProps {
  className?: string
}

const SectionBeautifulApps = (props: IProps): React.ReactElement => {
  return (
    <>
      <Section className={`${props.className}`}>
        <Container className="container mx-auto px-4 mb-20">
          <TextContainer>
            <h2 className="font-thin sm:text-5xl mb-0">Beautiful applications</h2>

            <h3 className="font-thin text-blue-700">built with Javascript</h3>
            <p className="mb-6">We use the latest and greatest web technologies to build highly scalable applications. </p>
            <div className="flex flex-row">
              <Link to={sitePaths.appLinks.contact} className="btn mr-4 btn-primary mr-1 lift w-1/3 text-base sm:text-base">
                Contact Us
              </Link>
              <Link
                to={sitePaths.appLinks.about}
                className="btn btn-secondary lift w-1/3
              text-base sm:text-base"
              >
                Learn more
              </Link>
            </div>
          </TextContainer>
          <ImageContainer>
            <div className="img-skewed img-skewed-left">
              <img src={desktopImg} alt="" className="screenshot img-fluid img-skewed-item-left" data-aos="img-skewed-item-left" />
            </div>
          </ImageContainer>
        </Container>
      </Section>
    </>
  )
}

export default SectionBeautifulApps

import { motion, useViewportScroll } from 'framer-motion'

import React from 'react'
import styleConfig from 'config/styleConfig'
import styled from '@emotion/styled'
import { useIntersection } from 'react-use'

const TechLogo = styled.div`
  flex-basis: 200px;
  flex-grow: 0;
  ${styleConfig.breakPoints.lg} {
    flex-basis: 300px;
  }
  display: flex;
  flex-direction: column;
  text-align: center;
  border-radius: 7px;
  border-color: #ccc;
  border-width: thin;
  border-style: solid;
  background: white;
  padding: 0;
  margin: 0 0.5rem 0.5rem;
  .img-container {
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  img {
    width: fit-content;
    height: fit-content;
  }
`
const TechLogoBlank = styled.div`
  margin: 0 0.5rem 0.5rem;
  width: 300px;
`

interface IProps {
  className?: string
}

const SectionTechnology = (props: IProps): React.ReactElement => {
  const intersectionRef = React.useRef(null)
  const intersection = useIntersection(intersectionRef, {
    root: null,
    rootMargin: '0px',
    threshold: 1,
  })
  const outOfView = Boolean(intersection && intersection.intersectionRatio < 1)
  const variants = {
    visible: { opacity: 1, scale: 1 },
    hidden: { opacity: 0, scale: 1 },
  }
  return (
    <section className={`${props.className} container mx-auto px-4 pb-10 relative`}>
      <div ref={intersectionRef} className="lg:text-center my-20 font-thin">
        <h3 className="text-3xl lg:text-4xl">
          Applications built using <br className="lg:hidden" />
          leading edge technology
        </h3>
        <motion.div animate={outOfView ? 'hidden' : 'visible'} initial="hidden" transition={{ duration: 2 }} variants={variants}>
          <h4>Some of the technology we use to build our applications</h4>
        </motion.div>
      </div>
      <div className="flex flex-row flex-wrap justify-center">
        <TechLogo data-aos="fade-down">
          <div className="img-container">
            <img className="img-responsive" src="/img/tech-logos/node.jpg" alt="NodeJS" />
          </div>
          <h4>Node</h4>
        </TechLogo>
        <TechLogo data-aos="fade-down">
          <div className="img-container">
            <img className="img-responsive" src="/img/tech-logos/angular.jpg" alt="Angular" />
          </div>
          <h4>Angular</h4>
        </TechLogo>
        <TechLogo data-aos="fade-down">
          <div className="img-container">
            <img className="img-responsive" src="/img/tech-logos/react.jpg" alt="React" />
          </div>
          <h4>React</h4>
        </TechLogo>
        <TechLogo data-aos="fade-down">
          <div className="img-container">
            <img className="img-responsive" src="/img/tech-logos/electron.png" alt="Electron" />
          </div>
          <h4>Electron</h4>
        </TechLogo>
        <TechLogo data-aos="fade-down">
          <div className="img-container">
            <img className="img-responsive" src="/img/tech-logos/express.jpg" alt="Express" />
          </div>
          <h4>Express</h4>
        </TechLogo>
        <TechLogo data-aos="fade-down">
          <div className="img-container">
            <img className="img-responsive" src="/img/tech-logos/aws.png" alt="Amazon Web Services" />
          </div>
          <h4>AWS</h4>
        </TechLogo>
        <TechLogo data-aos="fade-down">
          <div className="img-container">
            <img className="img-responsive" src="/img/tech-logos/facebook.jpg" alt="Facebook SDK" />
          </div>
          <h4>Facebook SDK</h4>
        </TechLogo>
        <TechLogo data-aos="fade-down">
          <div className="img-container">
            <img className="img-responsive" src="/img/tech-logos/php.jpg" alt="PHP" />
          </div>
          <h4>PHP</h4>
        </TechLogo>
        <TechLogo data-aos="fade-down">
          <div className="img-container">
            <img className="img-responsive" src="/img/tech-logos/laravel.jpg" alt="Laravel" />
          </div>
          <h4>Laravel</h4>
        </TechLogo>
        <TechLogo data-aos="fade-down">
          <div className="img-container">
            <img className="img-responsive" src="/img/tech-logos/javascript.jpg" alt="Javascript" />
          </div>
          <h4>Javascript</h4>
        </TechLogo>
        <TechLogo data-aos="fade-down">
          <div className="img-container">
            <img className="img-responsive" src="/img/tech-logos/mongo.jpg" alt="MongoDB" />
          </div>
          <h4>MongoDB</h4>
        </TechLogo>
        <TechLogo data-aos="fade-down">
          <div className="img-container">
            <img className="img-responsive" src="/img/tech-logos/mysql.jpg" alt="MySQL" />
          </div>
          <h4>MySQL</h4>
        </TechLogo>
        <TechLogo data-aos="fade-down">
          <div className="img-container">
            <img className="img-responsive" src="/img/tech-logos/mean.jpg" alt="Mean" />
          </div>
          <h4>Mean</h4>
        </TechLogo>
        <TechLogo data-aos="fade-down">
          <div className="img-container">
            <img className="img-responsive" src="/img/tech-logos/less.jpg" alt="Less" />
          </div>
          <h4>Less</h4>
        </TechLogo>
        <TechLogo data-aos="fade-down">
          <div className="img-container">
            <img className="img-responsive" src="/img/tech-logos/gulp.jpg" alt="Gulp" />
          </div>
          <h4>Gulp</h4>
        </TechLogo>
        <TechLogo data-aos="fade-down">
          <div className="img-container">
            <img className="img-responsive" src="/img/tech-logos/bootstrap.jpg" alt="Twitter Bootstrap" />
          </div>
          <h4>Twitter Bootstrap</h4>
        </TechLogo>
        <TechLogo data-aos="fade-down">
          <div className="img-container">
            <img className="img-responsive" src="/img/tech-logos/wordpress.jpg" alt="Wordpress" />
          </div>
          <h4>Wordpress</h4>
        </TechLogo>
        <TechLogo data-aos="fade-down">
          <div className="img-container">
            <img className="img-responsive" src="/img/tech-logos/html.jpg" alt="HTML 5" />
          </div>
          <h4>HTML 5</h4>
        </TechLogo>
        <TechLogoBlank />
        <TechLogoBlank />
        <TechLogoBlank />
        <TechLogoBlank />
      </div>
    </section>
  )
}

export default SectionTechnology
